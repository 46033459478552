<template>
  <div class="newsticker">
    <div v-show="enabled" class="roller" ref="roller">
      <slot/>
    </div>

    <slot v-if="!enabled" />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRafFn } from '@vueuse/core'

const props = defineProps({
  speed: {
    type: Number,
    default: 1,
  },
  enabled: {
    type: Boolean,
    default: true
  }
});

const roller = ref(null);
let translateX = 0;
let midIndex = 1;

onMounted(() => {
  pack();
  roll();
});

function pack() {
  const items = [...roller.value.children];
  do {
    items.forEach(n => roller.value.appendChild(n.cloneNode(true)));
  }
  while (!lastItemIsFarAway());
}

function roll() {
  midIndex = Math.ceil(roller.value.children.length / 2);

  useRafFn(({ delta }) => {
    translateX -= props.speed * (delta / 1000);
    roller.value.style.transform = `translateX(${translateX}px)`;

    const start = roller.value.children[0];
    const end = roller.value.children[midIndex];
    const startRect = start.getBoundingClientRect();
    const endRect = end.getBoundingClientRect();
    if (endRect.x < -endRect.width) {
      translateX += (endRect.x - startRect.x);
      [...roller.value.children].slice(0, midIndex).forEach(n => roller.value.appendChild(n));
    }
  })
}

function lastItemIsFarAway() {
  const items = [...roller.value.children];
  const screen = Math.max(window.innerWidth, 1920);
  return items.at(-1).getBoundingClientRect().x > (screen * 2) || items.length > 100;
}
</script>

<style lang="scss" scoped>
.newsticker {
  width: 100%;
  position: relative;
  overflow-x: hidden;

  .roller {
    position: absolute;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>