<template>
  <div class="wrapper" :class="{ first, last }">
    <div class="heading" @click="expand = !expand">
      <div class="title">{{ title }}</div>
      <FoldIcon class="fold icon" v-if="expand" />
      <ExpandIcon class="expand icon" v-else />
    </div>
    <div class="description" v-show="expand" v-html="description"></div>
  </div>
</template>

<script setup>
import FoldIcon from 'app/assets/images/mba/icons/fold.svg?component';
import ExpandIcon from 'app/assets/images/mba/icons/expand.svg?component';
import { ref } from "vue";

defineProps({
  title: String,
  description: String,
  first: Boolean,
  last: Boolean,
});

const expand = ref(false);
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 24px 0;
  border-bottom: 1px solid #D1D5DB;

  &.first {
    padding-top: 0;
  }

  &.last {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  gap: 8px;
}

.title {
  color: $blue-500;
  font-size: 24px;
  line-height: 140%;
  font-weight: 600;

  @include mobile {
    font-size: 18px;
    line-height: 140%;
  }
}

.description {
  color: $grey-300;
  font-size: 16px;
  line-height: 140%;
  margin-top: 8px;

  @include mobile {
    font-size: 14px;
    line-height: 140%; /* 19.6px */
  }
}

.icon {
  width: 24px;
  height: 33.6px;
  flex-shrink: 0;
}

.fold {
  color: #E5E7EB;
}

.expand {
  color: #DC003D;
}
</style>
